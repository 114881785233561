.pieChartAndOverviewContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border: 0.12rem solid rgb(221, 144, 55);
    padding: 2rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1;
    width: 54rem;
}

.tokenomicsOverviewContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.tokenomicsTitle {
  font-size: 3rem;
  font-weight: 700;
  color: rgb(221, 144, 55);
}

.tokenomicsDescriptionLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 1.3rem;
    /* width: 50rem; */

}
.pieChartAndListContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* border: 0.12rem solid rgb(221, 144, 55);
    padding: 2rem;
    border-radius: 0.5rem; */
    /* width: 50rem; */
}



.PieChartListContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* gap: 0.2rem; */
  /* width: 35rem; */
}

.listPieChartValuesAndBullets {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.75rem;
  list-style-position: inside;
}

.bulletslistPieChartValues {
  font-size: 4rem;
  width: 2.6rem;
}

.listPieChartValues {
  font-size: 1.54rem;
  /* color: rgb(255, 208, 150); */
}

@media (max-width: 1120px) {
  .pieChartAndOverviewContainer {
    width: 40rem;
  }
}

@media (max-width: 610px) {
  .pieChartAndOverviewContainer {
    width: 35rem;
  }
}

@media (max-width: 455px) {
  .pieChartAndListContainer {
    flex-direction: column;
    gap: 0rem;
    padding-bottom: 2rem;
  }

  .listPieChartValuesAndBullets {
    height: 2.4rem;
  }
}


