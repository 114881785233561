.BottomContainer {
  border-top: 0.2rem solid rgb(236, 152, 55);
  width: 100%;
  position: relative;
  background-color: black;
  margin-top: 10rem;
}

.BottomInfos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
}

.BottomInfosAndImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;
  padding-top: 3rem;
  height: 19rem;
}

.BottomTitleImg {
  width: 20rem;
}

.BottomSocialsContainer {
  padding-left: 11%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.BottomSocialsIcon {
  width: 3.4rem;
}

.BottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  margin-left: 10%;
  bottom: 1rem;
  color: rgb(110, 84, 53);
  height: 5rem;
}

.BottomInfosAndImgContainer {
  border-bottom: 0.1rem solid rgb(110, 84, 53);
  width: 80%;
  margin-left: 10%;
  position: relative;
}

.BottomImg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50rem;
}

@media (max-width: 1650px) {
  .BottomContainer {
    margin-top: 6rem;
  }
  .BottomImg {
    right: -5rem;
    width: 45rem;
  }
}

@media (max-width: 870px) {
  .BottomContainer {
    margin-top: 4rem;
  }
  .BottomImg {
    width: 39rem;
  }
}

@media (max-width: 720px) {
  .BottomContainer {
    margin-top: 2.5rem;
  }
  .BottomTitleImg {
    width: 18rem;
  }
  .BottomImg {
    width: 35rem;
  }
}

@media (max-width: 630px) {
  .BottomContainer {
    margin-top: 1rem;
  }
  .BottomImg {
    width: 28rem;
  }
}

@media (max-width: 600px) {
  
  .BottomImg {
    right: -4rem;
    width: 25rem;
  }
}

@media (max-width: 460px) {
  .BottomContainer {
    margin-top: 0rem;
  }
  .BottomTitleImg {
    width: 16rem;
  }
  .BottomImg {
    right: -4rem;
    width: 23rem;
  }
}