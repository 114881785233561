.IntegrationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.IntegrationsTitle {
  font-size: 3rem;
  font-weight: 700;
  color: rgb(236, 152, 55);
}

.IntegrationsListAndTileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.IntegrationsListTitle {
  font-size: 1.8rem;
  font-weight: 700;
  color: rgb(204, 133, 52);

}

.IntegrationsList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.IntegrationsListItemContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;
  /* border: 0.12rem solid rgb(236, 152, 55); */
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
}

.IntegrationsListItemTitleAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.IntegrationsListItemContainer:hover {
  /* background-color:rgb(206, 149, 83); */
  background-color:rgb(247, 133, 3);
  border-color: rgb(247, 133, 3);

  .IntegrationsListItemDescription {
    color: rgb(247, 133, 3);
  }
  
  .IntegrationsListItemTitle {
    color: black;
  }
}

.IntegrationsListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: black;
  border-radius: 0.5rem;
}

.IntegrationsListItemDescription {
  text-decoration: none;
  color: rgb(236, 152, 55);
  font-size: 1.3rem;
  text-align: center;
  text-justify: inter-word;

}

.IntegrationsListItemTitle {
  font-size: 1.8rem;
  font-weight: 700;
  color: rgb(204, 133, 52);
}

.IntegrationsListItemIcon {
  width: 5rem;
  height: 5rem;
}

@media (max-width: 580px) {
  /* .IntegrationsList {
    flex-direction: column;
    gap: 2rem;
  } */

  .IntegrationsListItemContainer {
    /* width: 21rem; */
    padding: 1rem;
  }

  .IntegrationsListItemIcon {
    width: 3rem;
    height: 3rem;
  }
}
