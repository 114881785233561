.walletContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.connectWalletButton {
  border: 1px solid #399236;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  background-color: #399236;
  color: whitesmoke;
  cursor: pointer;
}