.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 7rem;
  font-size: 2.7rem;
  font-weight: bolder;
  /* font-family: Open Sans, sans-serif; */
  font-family: doggie;
  /* border-bottom: 0.12rem solid rgb(221, 144, 55); */
  gap: 3rem;
  /* color: rgb(224, 194, 131); */
  /* color: rgb(184, 138, 86); */
  /* color: rgb(221, 144, 55); */
  color: rgb(214, 140, 56);
  /* background-color: rgb(202, 66, 24) */
  z-index: 1000;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.linksAndSocialsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.navbarSocialsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
}
.navbarLink {
  transition: color 0.25s ease-out;
}


.navbarLink, .navbarLink:visited, .navbarLink:hover, .navbarLink:active {
  text-decoration: none;
  color: inherit;
}

.navbarLink:hover {
  /* color: rgb(214, 147, 70); */
  color: rgb(255, 161, 54);
}

.navbarIcon {
  width: 3rem;
  image-rendering: auto;
}

.navbarIconRound {
  width: 3.43rem;
  image-rendering: auto;
}

.navbarIcon:hover, .navbarIconRound:hover {
  transform: scale(1.2);
  transition: 0.1s ease-out;
}


/* @media (max-width: 600px) {
  .navbarContainer {
    gap: 3rem;
  }
}

@media (max-width: 300px) {
  .navbarContainer {
    gap: 2rem;
  }
  .linksContainer {
    gap: 1rem;
  }
}

@media (max-width: 200px) {
  .navbarContainer {
    gap: 1rem;
  }
} */

/* Media query for smaller screens */
@media (max-width: 950px) {
  .navbarContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3%;
    padding-left: 2.5%;
    font-size: 2.9rem;
    gap: 0rem;
    height: 0rem;
    width: 97.5%;
  }

  .navbarIcon {
    width: 4rem;
  }

  .navbarIconRound {
    width: 4.5rem;
  }

  .menuIconAndLinksContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 3rem;
    width: 100%;
  }

  .linksAndSocialsContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: black;
    z-index: 9000;
    padding: 2rem 0rem 3rem 0rem;
    border-radius: 1rem;
    border: 0.4rem solid rgb(214, 140, 56);
    width: 77%;
  }

  .linksContainer {
    flex-direction: column;
  }

  .menuOpen .linksAndSocialsContainer {
    display: flex;
  }

  /* Add styles for the menu icon */
  .menuIcon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 3rem;
    gap: 0.5rem;
    color: rgb(214, 140, 56);
  }

  .bar {
    width: 3.6rem;
    height:0.55rem;
    background-color: rgb(214, 140, 56);
  }

  .navbarSocialsContainer {
    justify-content: center;
  }
}

