.claimWalletInput {
  width: 29rem;
  height: 2rem;
  border-radius: 0.2rem;
  border: 0.1rem solid #000000;
  padding: 1rem;
  font-size: 1.5rem;
}
.checkEligibilityButton {
  border-radius: 0.2rem;
  background-color: #d1651e;
  color: #ffffff;
  padding: 1rem 2rem;
  cursor: pointer;
}

.checkEligibilityButton:hover {
  background-color: #ff6600;
}

.unsuccessfulClaimText {
  color: red;
}

.successfulClaimTextAndTweetIt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

}

.successfulClaimText, .unsuccessfulClaimText {
  font-size: 2.8rem;
}
