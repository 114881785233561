.ArtAndDragForMoreContainer {
  display: flex;
  flex-direction: column;
}

.downloadMemesAndSlideContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2rem;
  padding-left: 2rem;
  align-items: center;
}

.ArtContainer {
  overflow-x: auto;
  cursor: grab; /* Change cursor when dragging */
  /* position: fixed; */
  /* bottom: 0; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.slideForMoreImg {
  width: 15rem;
  padding-bottom: 1rem;
}

.ArtContainer::-webkit-scrollbar {
  display: none;
}

.ArtContainer:active {
  cursor: grabbing; /* Change cursor when actively dragging */
}

.ArtImagesContainer {
  display: flex;
  white-space: nowrap;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  gap: 1rem;
}

.ArtImg {
  max-width: 100%;
  height: auto;
  width: 35rem;
  /* margin-right: 10px; */
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  image-rendering: auto;
  image-rendering: -o-crisp-edges;            /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
  -ms-interpolation-mode: nearest-neighbor;   /* IE (non-standard property) */
  user-select: none;
  border: 0.2rem solid rgb(236, 152, 55);
  border-radius: 1rem;
}

@media screen and (max-width: 1850px) {
  .ArtImg {
    width: 33rem;
  }
}

@media screen and (max-width: 720px) {
  .ArtImg {
    width: 25rem;
  }
}

@media screen and (max-width: 490px) {
  .ArtImg {
    width: 21rem;
  }
  
}
