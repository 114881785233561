.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  color: rgb(241, 220, 195);
  /* background: #1f140063; */
  /* background: rgb(0,0,0); */
  background: rgba(231,34,34,0.05);
  /* background: linear-gradient(90deg, rgba(0,0,0,0.25) 0%, rgba(231,34,34,0.2) 100%); */
  /* background: linear-gradient(90deg, rgba(231,34,34,0.1) 0%, rgba(0,0,0,0.25) 100%); */
  font-size: 1.7rem;
}

.homeNavbarPresentation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

