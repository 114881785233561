.RoadmapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: 1.5rem;
  border: 0.12rem solid rgb(221, 144, 55);
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 1);
  position: relative;
  width: 54rem;
  padding-bottom: 3rem;
}

/* .RoadmapAkuLambo {
  transform: translateX(-100rem) translateY(20rem);
  transition: 1s ease-in;
  -o-transition: 1s ease-in;
  -ms-transition: 1s ease-in;
  -moz-transition: 1s ease-in;
  -webkit-transition: 1s ease-in;

  position: absolute;
  width: 19rem;
  z-index: 1;
  right: -7rem;
  top: -7.3rem;
} */

.RoadmapAkuLambo {
  position: absolute;
  width: 19rem;
  z-index: 1;
  right: -2rem;
  top: -7.3rem;
}

.roll-out {
  animation: .4s rollout;
}

.RoadmapTitle {
  font-size: 3rem;
  font-weight: 700;
  color: rgb(221, 144, 55);
}

.RoadmapContent {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 85%;
}


.RoadmapContentItem {
  text-align: center;
}

.RoadmapContentAndProgressBarItem  {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 1120px) {
  .RoadmapContainer {
    width: 40rem;
  }
}

@media (max-width: 610px) {
  .RoadmapContainer {
    width: 35rem;
  }
}


@media (max-width: 680px) {
  .RoadmapAkuLambo {
    width: 17rem;
    top: -6.5rem;
  }
}

@media (max-width: 600px) {
  .RoadmapAkuLambo {
    width: 16rem;
    top: -6.5rem;
  }
}


