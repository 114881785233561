.presentationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  gap: 9rem;
}

.presentationImagesContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.presentationAkamaruImg {
  width: 32.5rem;
  padding-top: 6rem;
  z-index:1;
}

.buyAndLearnMoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;
}

.externalLinkIcon {
  height: 1.3rem;
}

.buyButton, .learnMoreButton, .buyButton:visited, .learnMoreButton:visited {
  text-decoration: none;
}

.buyButton {
  padding: 0.5rem 2rem;
  border: 0.2rem solid rgb(221, 144, 55);
  border-radius: 1.5rem;
  color: black;
  background-color: rgb(221, 144, 55);
  font-size: 1.9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  transition: 0.3s;
}

.learnMoreButton {
  padding: 0.5rem 2rem;
  border: 0.2rem solid rgb(221, 144, 55);
  border-radius: 1.5rem;
  color: rgb(221, 144, 55);
  background-color: black;
  font-size: 1.9rem;
  transition: 0.3s;
}

.learnMoreButton:hover {
  background-color: rgb(221, 144, 55);
  color: black;
}

.buyButton:hover {
  background-color: rgb(255, 161, 54);
  border-color: rgb(255, 161, 54);
}

.presentationBigTitleAndText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48%;
}

.presentationText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* width: 50%; */
}

.presentationDescription {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  text-align: justify;
  text-justify: inter-word;
  justify-content: center;
}

.presentationBigTitle {
  width: 31rem;
  font-size: 13rem;
  font-weight: 700;
  /* color: rgb(182, 36, 0); */
  color: rgb(221, 144, 55);
  text-shadow: 0.4rem 0.4rem 0.5rem rgba(182, 36, 0, 0.7);
  font-style: italic;
  padding-bottom: 0.5rem;
}

.presentationTitle {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(221, 144, 55);
  font-style: italic;
  padding-bottom: 1rem;
}

.airdroppedCountsPresentationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
  gap: 2rem;
  padding-top: 0.6rem;
}

.airdroppedCountsPresentation {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 0.15rem solid rgb(221, 144, 55);
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  /* background-color: rgba(206, 148, 83, 0.1); */
  background-color: black;
}

.airdroppedCountsPresentationNumber {
  font-size: 1.8rem;
  font-weight: 700;
  color: rgb(221, 144, 55);

}

.presentationWhy {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
  text-justify: inter-word;
  justify-content: center;
}

.presentationWhyTitle {
  font-weight: 500;
  color: rgb(221, 144, 55);
  font-style: italic;
}

@media (max-width: 1750px) {
  .presentationBigTitle {
    font-size: 9rem;
  }
  .presentationContainer {
    gap: 8rem;
  }
}

@media (max-width: 1600px) {
  .presentationBigTitle {
    font-size: 8rem;
  }
  .presentationContainer {
    gap: 7rem;
  }
}

@media (max-width: 1500px) {
  .presentationContainer {
    gap: 5rem;
  }
}

@media (max-width: 1470px) {
  .presentationBigTitle {
    font-size: 8rem;
  }

}

@media (max-width: 1400px) {
  .presentationContainer {
    gap: 3.5rem;
  }
}

@media (max-width: 560px) {
  .presentationBigTitle {
    font-size: 7rem;
  }
}

@media (max-width: 190px) {
  .presentationBigTitle {
    font-size: 6rem;
  }
}

@media (max-width: 1150px) {
  .presentationContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding-top: 0rem;
    width: 70%;
  }
  .presentationBigTitleAndText {
    width: 80%;
  }
  .presentationAkamaruImg {
    visibility: hidden;
    padding-top: 0rem;
    height: 0rem;
  }

  .presentationBigTitle {
    width: 36rem;
  }
}

@media (max-width: 768px) {
  .presentationContainer {
    padding-top: 0rem;
    width: 80%;
  }

  .presentationBigTitle {
    width: 33rem;
  }
}

@media (max-width: 550px) {
  .presentationContainer {
    padding-top: 0rem;
    width: 95%;
  }

}

@media (max-width: 250px) {
  .presentationBigTitle {
    width: 28rem;
  }
}