.TradeContainer {
  /* height: 17rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.TradeTitle {
  font-size: 3rem;
  font-weight: 700;
  color: rgb(221, 144, 55)
}

.TradeExchangesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.TradeExchange {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 4rem;
}

.TradeExchange, .TradeExchange:visited, .TradeExchange:hover, .TradeExchange:active {
  font-size: 2rem;
  text-decoration: none;
  color: inherit;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* background-color: rgb(221, 144, 55); */
  background-color: rgb(221, 144, 55);
}

.TradeExchangeImg, .TradeExchangeImgAVNU {
  height: 3rem;
}

/* .TradeContract {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  cursor: pointer;
  border: 1px solid;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: black;
  border-color: rgb(221, 144, 55);
  font-size: 1.85rem;
} */

/* .TradeContractTitle {
  color: rgb(221, 144, 55);
} */


.TradeExchange:hover {
  background-color: rgb(247, 133, 3);
  color: white;
}

@media (max-width: 600px) {
  .TradeExchangeImg, .TradeExchangeImgAVNU {
    height: 2.7rem;
  }
  .TradeExchange {
    width: 14rem;
    height: 4rem;
  }
}
@media (max-width: 190px) {
  .TradeExchangeImg, .TradeExchangeImgAVNU {
    height: 2.4rem;
  }
  .TradeExchange {
    width: 12rem;
    height: 4rem;
  }
}

