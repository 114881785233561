.buttonDownLoadMemes {
  padding: 0.6rem 1rem;
  border: 0.2rem solid rgb(221, 144, 55);
  border-radius: 0.5rem;
  color: black;
  background-color: rgb(221, 144, 55);
  font-size: 1.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  transition: 0.3s;
  cursor: pointer;
  font-family: doggie;
}

.buttonDownLoadMemes:hover {
  background-color: rgb(255, 161, 54);
  border-color: rgb(255, 161, 54);
}