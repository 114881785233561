.countdownTimerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;

}

.countdownTimerRefresh {
  color: #ff6600;

}

.countdownTimerLeftContainer {
  border: 1px solid #000;
  background-color: #ff6600;
  border-radius: 0.3rem;
  text-align: center;
  padding: 1rem;
  width: 10rem;
  font-size: 3rem;
}