.tokenomicsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

.tokenomicsAkamaruImgAndText {
  position: relative;
}

.tokenomicsAkamaruImg {
  position: absolute;
  width: 7rem;
  z-index: 0;
  left: 5rem;
  top: -1.7rem;
  cursor: pointer;
  transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  -ms-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tokenomicsAkamaruImg:focus {
  outline: none !important;
}

.tokenomicsAkamaruText {
  position: absolute;
  visibility: hidden;
  width: 18rem;
  z-index: 0;
  left: 11.1rem;
  top: -6.5rem;
  cursor: pointer;
  transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  -ms-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
}

.tokenomicsAkamaruImgAndText:hover > .tokenomicsAkamaruImg, .tokenomicsAkamaruImgAndText:hover > .tokenomicsAkamaruText{
  transform: translateY(-6.8rem);
}

.tokenomicsAkamaruImgAndText:hover > .tokenomicsAkamaruText {
  visibility: visible;
}


@media (max-width: 610px) {
  .tokenomicsAkamaruImg {
    left: 0rem;
  }
  .tokenomicsAkamaruText {
    left: 6.1rem;
  }
}

@media (max-width: 610px) {
  .tokenomicsAkamaruImg {
    left: -5rem;

  }
  .tokenomicsAkamaruText {
    left: 1.1rem;
  }
}
