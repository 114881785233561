.claimContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 3rem;
  font-size: 2.3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(63,102,187,1) 50%, rgba(253,111,29,1) 100%, rgba(0,0,0,1) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  color: whitesmoke;
  /* color: rgb(241, 220, 195); */
}

.loadingWalletMsg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 10rem;
}

.claimWalletAndText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3rem;
}

.claimWalletAndDisclaimerAndListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  background-color: rgba(0,0,0,0.7);
  padding: 5rem;
  border-radius: 0.5rem;
}

.claimTitle, .loadingWalletMsg {
  padding-top:2rem;
  font-size: 1.5rem;
}

.claimDisclaimerAndListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.successfulClaimContainer {
  background-color: black;
  padding: 3rem 10rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  font-size: 2.4rem;
  position: relative;
}

.successfulClaimText {
  color: rgb(28, 206, 12);
}

.successfulClaimParakuImg {
  width: 16rem;
}

.successfulClaimbackArrow {
  position: absolute;
  width: 3rem;
  z-index: 1;
  top: 2rem;
  left: 2.5rem;
  cursor: pointer;
}

.tweetItContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: whitesmoke;
}

.TweetItIcon {
  width: 3rem;
}

.tweetItContainer:hover {
  scale: 1.2;
}

.claimDisclaimer {
  color: rgb(218, 153, 123)
}

.claimButton {
  /* border: 1px solid rgb(207, 143, 46); */
  border-radius: 0.3rem;
  width: 13rem;
  padding: 0.5rem 0rem;
  background-color: #d1651e;
  color: whitesmoke;
  cursor: pointer;
  font-size: 2.6rem;
  text-align: center;
}

.claimButton:hover {
  background-color: #ff6600;

}

.claimList {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: start; */
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.claimItem {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.notEligible {
  color: rgb(231, 137, 121);
}

.akuGetAmountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.akuBalanceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.akuBalanceValueImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.akuLogo {
  width: 2.3rem;
}


.claimTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  font-size: 3rem;
}
.starknetIdLogo {
  width: 6.5rem;
}

.akuLogoTitle {
  width: 6rem;
}

.claimTextContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 0.2rem;
}

.BottomLinksIcon {
  display: flex;
  text-align: center;
  width: 3rem;
}
.BottomLinksIcon:hover {
  scale: 1.25;
}

.BottomSocialContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  gap: 2rem;
  background-color: rgba(0,0,0,0.5);
  border-radius: 0.5rem;
}

.joinMovementText {
  font-size: 2.5rem;
  color: rgb(253, 111, 29);
}

.JoinMovementSocialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background-color: rgba(0,0,0,0.7);
  border-radius: 0.5rem;
  gap: 2rem;
}

.JoinMovementLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.doneJoinMovementButton {
  border-radius: 0.3rem;
  width: 13rem;
  padding: 0.5rem 0rem;
  background-color: #d1651e;
  color: whitesmoke;
  cursor: pointer;
  font-size: 2.6rem;
  text-align: center;
}

.doneJoinMovementButton:hover {
  background-color: #ff6600;
}

.joinMovementDescription {
  color: whitesmoke;
  font-size: 2.3rem;
}

.joinMovementTitle {
  color: rgb(253, 111, 29);
  font-size: 3rem;
}

@media (max-width: 190px) {
  .claimContainer {
    font-size: 2rem;
  }
}