.progress-bar-container {
  width: 100%;
  border: 0.12rem solid rgb(221, 144, 55);
  border-radius: 0.2rem;
  overflow: hidden;
}

.progress-bar, .progress-bar-completed {
  background-color: rgb(247, 133, 3);
  height: 2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}



.progress-text {
  margin-right: 0.2rem;
}