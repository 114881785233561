.copyClipboardButtonContainer {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.copyClipboardButtonContainer:focus {
  outline: none !important;
}


.copyClipboardButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.copyClipboardButtonContainerIcon {
  height: 1.8rem;
}