@import-normalize;

@font-face {
  font-family: doggie;
  src: url(assets/fonts/Doggie.ttf);
}

body {
  margin: 0;
  font-family: doggie;
  /* font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  height: 100%;
  font-weight: 450;
}

html, #root {
  height: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media (max-width: 1500px) {
  html {
    font-size: 15px;
  } 
}

@media (max-width: 1250px) {
  html {
    font-size: 14px;
  } 
}

@media (max-width: 1150px) {
  html {
    font-size: 13px;
  } 
}

@media (max-width: 1040px) {
  html {
    font-size: 11px;
  } 
}

@media (max-width: 800px) {
  html {
    font-size: 10px;
  } 
}

@media (max-width: 500px) {
  html {
    font-size: 9px;
  } 
}

@media (max-width: 410px) {
  html {
    font-size: 8px;
  } 
}

@media (max-width: 360px) {
  html {
    font-size: 7px;
  } 
}

@media (max-width: 310px) {
  html {
    font-size: 6px;
  } 
}

@media (max-width: 275px) {
  html {
    font-size: 5px;
  } 
}

@media (max-width: 230px) {
  html {
    font-size: 4.5px;
  } 
}